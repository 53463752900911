import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNotifications, addMarkAsRead, addAllMarkAsRead } from 'redux/action/Notification';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, styled } from '@mui/material/styles';
import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    Button,
} from '@mui/material';
import moment from 'moment';

import { isArrayCheck } from 'views/utilities/common';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({markAsRead}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const { all_notifications } = useSelector((state) => state._notification);
    const { role } = useSelector((state) => state._auth);

    const [notifyToShow, setNotifyToShow] = useState(9);

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };

    useEffect(() => {
        if (markAsRead === undefined) {
            dispatch(getAllNotifications(false));
        } else {
            dispatch(getAllNotifications(markAsRead));
        }
    }, [markAsRead]);

    return (
        <List
            classname="list-wrapper"
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {
                isArrayCheck(all_notifications) &&
                all_notifications?.slice(0, notifyToShow).map((data, index) => (
                    <React.Fragment key={index}>
                        <ListItemWrapper>
                            <ListItem alignItems="center">
                                <ListItemText primary={data?.actionType} />
                                <ListItemSecondaryAction>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={12}>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                {moment(data?.created_at).format('DD MMM YYYY h:mm a')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                                {
                                    data?.markAsRead === false  && (
                                        <div style={{cursor: "pointer"}}>
                                            <CloseIcon className="closeicon" onClick={() => dispatch(addMarkAsRead({ id: data?._id }))} />
                                        </div>
                                    )
                                }
                            </ListItem>
                            <Grid container direction="column" className="list-container">
                                <Grid item xs={12} sx={{ pb: 2 }}>
                                    <p className="desc-text">
                                        {data?.description}
                                    </p>
                                    {
                                        data?.action === "user-created" && (
                                            <div>
                                                <Typography variant="subtitle2"> Name: {data?.userId?.fullname} </Typography>
                                                <Typography variant="subtitle2"> Email: {data?.userId?.email} </Typography>
                                                <Typography variant="subtitle2"> Role: {data?.userId?.role} </Typography>
                                            </div>
                                        )
                                    }

                                    {
                                        (data?.action === 'order-created' || data?.action === 'order-updated') && (
                                            <div>
                                                <Typography variant="subtitle2"> 
                                                    Order Id : <span className="link-notif" onClick={() => navigate("/dashboard/orders", {state: data?.orderId?._id?.substring(0, 10)})}>{data?.orderId?._id?.substring(0, 10)}</span>
                                                </Typography>
                                                {
                                                    (data?.nextAction && data?.nextAction !== "") && (
                                                        <div className="next-color">{data?.nextAction ?? ""}</div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }

                                    {
                                        (data?.action === 'product-created' || data?.action === 'product-updated') && (
                                            <div>
                                                <Typography variant="subtitle2"> 
                                                    Name : 
                                                    <span 
                                                        className="link-notif" 
                                                        onClick={() => 
                                                        navigate(role === "admin" ? "/dashboard/products-approved" : "/dashboard/products", { state: { productName:data?.productId?.name, value: data?.productId?.isApproved }  })}>
                                                        {data?.productId?.name}
                                                    </span>
                                                </Typography>
                                                {
                                                    data?.actionType === "Product Rejected" && (
                                                        <Typography variant="subtitle2"> Comment : <span className="comment-color">{data?.comment ?? ""}</span></Typography>
                                                    )
                                                }
                                                {
                                                    (data?.nextAction && data?.nextAction !== "") && (
                                                        <div className="next-color">{data?.nextAction ?? ""}</div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }

                                    {
                                        (data?.action === 'payment-created' || data?.action === 'payment-updated') && (
                                            <div>
                                                <Typography variant="subtitle2"> 
                                                    No : 
                                                    <span 
                                                        className="link-notif" 
                                                        onClick={() => 
                                                        navigate(role === "admin" ? "/dashboard/payment-approval" : data.actionType === "Request Payment Rejected" ? "/dashboard/request-payment" : "/dashboard/history-payment")}>
                                                        {data?.paymentId?.requestNo}
                                                    </span>
                                                </Typography>
                                                {
                                                    data?.actionType === "Request Payment Rejected" && (
                                                        <Typography variant="subtitle2"> Comment : <span className="comment-color">{data?.comment ?? ""}</span></Typography>
                                                    )
                                                }
                                                {
                                                    (data?.nextAction && data?.nextAction !== "") && (
                                                        <div className="next-color">{data?.nextAction ?? ""}</div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </ListItemWrapper>
                        <Divider />
                    </React.Fragment>
                ))
            }

            {isArrayCheck(all_notifications) && all_notifications.length > Number(notifyToShow) && (
                <div className="notifications">
                    <div className="see-more text-center">
                        <Button
                            onClick={() => {
                                if (isArrayCheck(all_notifications) && all_notifications.length < notifyToShow) {
                                    setNotifyToShow(
                                        notifyToShow +
                                            ((all_notifications.length - notifyToShow) % 0 ? 9 : notifyToShow - all_notifications.length)
                                    );
                                } else {
                                    setNotifyToShow(notifyToShow + 9);
                                }
                            }}
                        >
                            See More
                        </Button>
                    </div>
                </div>
            )}
        </List>
    );
};

export default NotificationList;
