import * as React from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import '../../../../assets/scss/style.scss';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export default function ModalNotes({ 
	comments, 
	setcomments,
	visible,
	handleClose,
	onSave, 
}) {
    const { role } = useSelector((state) => state._auth);

    return (
        <div>
            <Dialog
                open={visible}
				onClose={handleClose}
				maxWidth="md"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="approval-detail-popup" style={{width: "inherit"}}>
                    <div className="confirm-approval">
                        <div className="row">
                            <div className="col-lg-12">
                                <p>Notes</p>
                                <textarea
                                    name="Write your note..."
                                    placeholder="Write your note..."
                                    rows="4"
                                    onChange={(e) => setcomments(e.target.value)}
                                    value={comments}
                                ></textarea>
                            </div>
                        </div>
                        <div className="approval-btn">
                            <div className="text-center">
                                <div className="d-flex justify-content-center mt-4">
									<div className="btn-cancel" style={{margin: "0px", marginRight: "20px"}}>
										<Button variant="contained" className="btn-primary" onClick={handleClose}>
											Cancel
										</Button>
									</div>
                                    <Button
                                        variant="contained"
                                        className="approve"
                                        onClick={() => onSave()}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
