import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import '../../../../assets/scss/style.scss';
import HeaderApprovalPopup from './Table/HeaderApprovalPopup';
import RowApprovalPopup from './Table/RowApprovalPopup';
import ModalRejection from "../ModalRejection";
import Button from '@mui/material/Button';

import { isArrayCheck } from 'views/utilities/common';
import { useDispatch, useSelector } from 'react-redux';
import { editProduct } from 'redux/action/Product';
import makeToast from 'utils/Toaster';
import { useNavigate } from 'react-router';
import { getAllUserListing } from 'redux/action/User';
import { Log } from 'utils/helper';
import { base_url_new } from 'utils/config';

export default function ApprovalPopup({ visible, setVisible, data }) {
    const { all_users } = useSelector((state) => state._user);
    const [showReject, setShowReject] = React.useState(false);
    const [manufacture, setmanufacture] = React.useState('');
    const [comments, setcomments] = React.useState('');
    const [notes, setnotes] = React.useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { role } = useSelector((state) => state._auth);
    const editable = role === "designer" ? true : ((data?.isApproved !== "approvedByAdmin" && data?.isApproved !== "rejected"));

    const handleClose = () => {
        setVisible(false);
    };

    React.useEffect(() => {
        dispatch(getAllUserListing({ isApproved: 'approved', role: 'manufacturer' }));
    }, []);

    const updateProductStatus = (value) => {
        console.log('manufacture', manufacture, value);

        if (value === 'approvedByAdmin' && manufacture) {
            dispatch(
                editProduct({ id: data?._id, isApproved: value, manufacture: manufacture }, navigate, { isApproved: value, role: role })
            );
        } else if (value === 'rejected' || value === 'approved' || value === 'unapproved') {
            const roleComment = {};
            if (role === "designer") {
                roleComment.designerComment = comments;
            } else {
                roleComment.adminComment = comments;
            }
            dispatch(editProduct({ id: data?._id, isApproved: value, type: 'approved', ...roleComment }, navigate, { isApproved: value, role: role }));
        } else {
            makeToast('error', 'Kindly Add Manufacture!');
        }
        setVisible(false);
    };
    Log('Data==>', data);
    return (
        <div>
            <Dialog
                open={visible}
                onClose={handleClose}
                maxWidth="xl"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="approval-detail-popup">
                    <div className="heading d-flex justify-content-between align-item-center">
                        <h2>Product Details</h2>
                        <CloseIcon onClick={handleClose} />
                    </div>
                    <div className="manufecturer">
                        <div className="d-flex cost-name align-items-center justify-content-between">
                            <strong>Designer Name</strong>
                            <div className="margin"></div>
                            <p className="m-0">{data?.designer?.fullname}</p>
                        </div>
                    </div>
                    <div className="manufecturer">
                        <div className="d-flex cost-name align-items-center justify-content-between">
                            <strong>Designer Email</strong>
                            <div className="margin"></div>
                            <p className="m-0">{data?.designer?.email}</p>
                        </div>
                    </div>
                    {role === 'designer' ? (
                        <div className="manufecturer">
                            <div className="d-flex cost-name align-items-center justify-content-between">
                                <strong>Manufacture Name</strong>
                                <div className="margin"></div>
                                <p className="m-0">{data?.manufacture?.fullname}</p>
                            </div>
                        </div>
                    ) : null}
                    {
                        data?.additionalDocs?.map((doc, index) => (
                            <React.Fragment key={index}>
                                {
                                    index === 0 ? (
                                        <div className="manufecturer">
                                            <div className="d-flex cost-name align-items-center justify-content-between">
                                                <strong>PDF of CAD Drawings, Technical Specs, or Other Documents</strong>
                                                <div className="margin"></div>
                                                <div>
                                                    <a href={`${base_url_new}${doc.url}`} download={true}>
                                                        {`${doc.name}`}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="manufecturer">
                                            <div className="d-flex cost-name align-items-center justify-content-between">
                                                <div className="margin"></div>
                                                <div>
                                                    <a href={`${base_url_new}${doc.url}`} download={true}>
                                                        {`${doc.name}`}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </React.Fragment>
                        ))
                    }
                    {
                        data?.isApproved !== "approved" && (
                            <React.Fragment>
                                <div className="manufecturer">
                                    <div className="d-flex cost-name align-items-center justify-content-between">
                                        <strong className="reject">Rejected Comment from Admin</strong>
                                        <div className="margin"></div>
                                        <p style={{whiteSpace: "pre-wrap"}} className="m-0">{data?.adminComment !== "" ? data?.adminComment : "-"}</p>
                                    </div>
                                </div>
                                <div className="manufecturer">
                                    <div className="d-flex cost-name align-items-center justify-content-between">
                                        <strong className="reject">Rejected Comment from Designer</strong>
                                        <div className="margin"></div>
                                        <p style={{whiteSpace: "pre-wrap"}} className="m-0">{data?.designerComment !== "" ? data?.designerComment : "-"}</p>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    }
                    {isArrayCheck(data?.variant) ? (
                        <div className="table-responsive">
                            <table class="table">
                                <HeaderApprovalPopup isAdmin={role === 'admin'} />
                                <tbody>
                                    {isArrayCheck(data?.variant) &&
                                        data?.variant?.map((x, id) => <RowApprovalPopup data={x} key={id} product={data} variant={true} editable={editable} />)}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="table-responsive">
                            <table class="table">
                                <HeaderApprovalPopup isAdmin={role === 'admin'} />
                                <tbody>
                                    <RowApprovalPopup data={data} variant={false} product={data} editable={editable} />
                                </tbody>
                            </table>
                        </div>
                    )}

                    {
                        (editable) && (
                            <div className="confirm-approval">
                                <div className="row">
                                    {role === 'designer' ? null : (
                                        <div className="col-lg-6 mb-1">
                                            <p>Link Manufacturers</p>
                                            <select id="cars" onChange={(e) => setmanufacture(e.target.value)} value={manufacture}>
                                                <option value={''}>{'Select Manufacture'}</option>
                                                {isArrayCheck(all_users) &&
                                                    all_users?.map((data, id) => <option value={data?._id}>{data?.fullname}</option>)}
                                            </select>
                                        </div>
                                    )}
                                    {/* <div className="col-lg-12">
                                        <p>Comment</p>
                                        <textarea
                                            name="Write your comment..."
                                            placeholder="Write your comment..."
                                            onChange={(e) => setcomments(e.target.value)}
                                            value={comments}
                                        ></textarea>
                                    </div> */}
                                    {/* <div className="col-lg-12">
                                        <p>Notes</p>
                                        <textarea
                                            name="Write notes..."
                                            placeholder="Write notes..."
                                            onChange={(e) => setnotes(e.target.value)}
                                            value={notes}
                                        ></textarea>
                                    </div> */}
                                </div>
                                <div className="approval-btn">
                                    <div className="text-center">
                                        <p>This product needs approval from you</p>
                                        <div className="d-flex justify-content-center mt-4">
                                            <Button
                                                variant="contained"
                                                className="reject"
                                                onClick={() => {
                                                    // if (role === 'admin') {
                                                    //     updateProductStatus('rejected');
                                                    // } else {
                                                    //     updateProductStatus('unapproved');
                                                    // }
                                                    setShowReject(true);
                                                }}
                                            >
                                                Reject
                                            </Button>
                                            <Button
                                                variant="contained"
                                                className="approve"
                                                onClick={() => {
                                                    if (role === 'admin') {
                                                        updateProductStatus('approvedByAdmin');
                                                    } else {
                                                        updateProductStatus('approved');
                                                    }
                                                }}
                                            >
                                                Approve
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Dialog>
            <ModalRejection 
                visible={showReject}
                handleClose={() => setShowReject(false)}
                comments={comments}
                setcomments={setcomments}
                onReject={() => {
                    if (role === 'admin') {
                        updateProductStatus('rejected');
                    } else {
                        updateProductStatus('unapproved');
                    }

                    setShowReject(false);
                }}
            />
        </div>
    );
}
